import { create } from 'zustand'
import { PER_PAGE } from '@/data'

export const usePaginationStore = create((set, get) => ({
  total: 0,
  pagination: {
    pageIndex: 0,
    pageSize: PER_PAGE,
    disabled: false,
  },
  prevPage: null,
  nextPage: null,
  setPrevPage: (prevPage) => {
    set({ prevPage })
  },
  setNextPage: (nextPage) => {
    set({ nextPage })
  },
  setPagination: (updater) =>
    set((state) => {
      const newPagination = updater(state.pagination)
      return { pagination: newPagination }
    }),
  setTotal: (total) => {
    set({ total })
  },
  enablePagination: () => {
    const pagination = { ...get().pagination }
    set({ pagination: { ...pagination, disabled: false } })
  },
  disablePagination: () => {
    const pagination = { ...get().pagination }
    set({ pagination: { ...pagination, disabled: true } })
  },
}))
