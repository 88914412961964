import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const usePreferencesStore = create(
  persist(
    (set, get) => ({
      openedItems: [],
      closedItems: [],
      openItem: (id) => {
        const { openedItems, closedItems } = get()
        if (!openedItems.includes(id)) {
          set({
            openedItems: [...openedItems, id],
            closedItems: closedItems.filter((item) => item !== id),
          })
        }
      },
      closeItem: (id) => {
        const { openedItems, closedItems } = get()
        if (!closedItems.includes(id)) {
          set({
            openedItems: openedItems.filter((item) => item !== id),
            closedItems: [...closedItems, id],
          })
        }
      },
      toggleItem: (id) => {
        const { openedItems, closedItems } = get()
        const isOpen = openedItems.includes(id)

        if (isOpen && !closedItems.includes(id)) {
          set({
            openedItems: openedItems.filter((item) => item !== id),
            closedItems: [...closedItems, id],
          })
        } else if (!isOpen && !openedItems.includes(id)) {
          set({
            openedItems: [...openedItems, id],
            closedItems: closedItems.filter((item) => item !== id),
          })
        }
      },
      getOpenState: (id) => {
        const { closedItems } = get()
        const isClosed = closedItems.includes(id)

        if (isClosed) return false
        return true
      }
    }),
    {
      name: 'preferences-storage',
    }
  )
)

export default usePreferencesStore
