import { create } from 'zustand'
import { matchfyApi, spotifyApi } from '@/api'

export const useArtistStore = create((set, get) => ({
  artistIds: [],
  artists: [],
  isLoading: false,
  limitReached: false,
  success: undefined,
  error: undefined,
  setArtistIds: (artistIds) => set({ artistIds }),
  setArtists: (artists) => set({ artists }),
  setLimitReached: (limitReached) => set({ limitReached }),
  fetchArtists: async (spotifyArtistIds) => {
    if (!spotifyArtistIds.length) return
    set({ isLoading: true })

    try {
      const { artists } = await spotifyApi.getArtistsBulk(spotifyArtistIds)
      set({
        artists: artists.map((artist) => ({ ...artist, analytics: [] })),
        error: undefined,
        isLoading: false,
      })
    } catch (error) {
      console.error(error)
      set({ error })
    } finally {
      set({ isLoading: false })
    }
  },
  addArtist: async (artist) => {
    const { artistIds, artists } = get()

    try {
      set({ isLoading: true })
      await matchfyApi.addArtist(artist.id)
      set({
        artistIds: [...artistIds, artist.id],
        artists: [...artists, artist],
        success: 'feedback.success.artist.add',
      })
    } catch (error) {
      console.error(error)
      const errorCode = error?.response?.data?.message || 'feedback.error.default'

      if (errorCode === 'errors.artist.limit_reached') {
        set({ limitReached: true })
      }

      set({ error: errorCode })
    } finally {
      set({ isLoading: false })
    }
  },
  deleteArtist: async (artistId) => {
    const { artistIds, artists } = get()

    try {
      set({ isLoading: true })
      await matchfyApi.deleteArtist(artistId)
      set({
        artists: artists.filter((artist) => artist.id !== artistId),
        artistIds: artistIds.filter((id) => id !== artistId),
        success: 'feedback.success.artist.remove',
      })
    } catch (error) {
      console.error(error)
      set({ error })
    } finally {
      set({ isLoading: false })
    }
  },
  resetFeedback: () => set({ success: undefined, error: undefined }),
}))

export default useArtistStore
