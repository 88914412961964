import { create } from 'zustand'
import { matchfyApi, spotifyApi } from '@/api'

export const useQueueStore = create((set, getState) => ({
  error: false,
  isLoading: false,
  success: false,
  loadingTrackId: undefined,
  queryKey: undefined,
  setQueryKey: (queryKey) => set({ queryKey }),
  setIsLoading: (isLoading) => set({ isLoading }),
  setLoadingTrackId: (loadingTrackId) => set({ loadingTrackId }),
  acceptPush: async (playlistId, trackId, submitterId) => {
    try {
      const { scoreOffset } = await matchfyApi.acceptPush(playlistId, trackId, submitterId)
      set({ success: 'feedback.success.track.add.with_credits', scoreOffset })
      return scoreOffset
    } catch (error) {
      set({ error: 'feedback.error.default' })
      console.log(error)
    }
  },
  archivePush: async (playlistId, trackId) => {
    try {
      await matchfyApi.archivePush(playlistId, trackId)
      set({ success: 'feedback.success.track.remove.queue' })
    } catch (error) {
      set({ error: 'feedback.error.default' })
      console.log(error)
    }
  },
  undoPushAccept: async (playlistId, trackId, submitterId) => {
    try {
      const { scoreOffset } = await matchfyApi.undoPushAccept(playlistId, trackId, submitterId)
      set({ success: 'feedback.success.track.remove.playlist', scoreOffset })
      return scoreOffset
    } catch (error) {
      set({ error: 'feedback.error.default' })
      console.log(error)
    }
  },
  rejectPush: async (playlistId, trackId, submitterId, feedback = false) => {
    try {
      const { scoreOffset } = await matchfyApi.rejectPush(playlistId, trackId, submitterId, feedback)
      set({ success: 'feedback.success.track.remove.queue', scoreOffset })
      return scoreOffset
    } catch (error) {
      set({ error: 'feedback.error.default' })
      console.log(error)
    }
  },
  followArtists: async (artistIds) => {
    try {
      await spotifyApi.followArtists(artistIds)
    } catch (error) {
      console.log(error)
    }
  },
  unfollowArtists: async (artistIds) => {
    try {
      await spotifyApi.unfollowArtists(artistIds)
    } catch (error) {
      console.log(error)
    }
  },
  resetFeedback: () => set({ success: false, error: false }),
  resetLoadingTrackId: () => set({ loadingTrackId: undefined }),
}))

export default useQueueStore
