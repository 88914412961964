import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useRecentMatchStore = create(
  persist(
    (set, get) => ({
      recentMatches: null,
      setRecentMatches: (recentMatches) => {
        return set({ recentMatches })
      },
      addRecentMatch: (match) => {
        if (get().recentMatches?.find((m) => m.track.id === match.track.id)) {
          return
        }

        const recentMatches = [...(get().recentMatches || [])]
        recentMatches.unshift(match)
        set({ recentMatches })
      },
      removeRecentMatch: (trackId) => {
        const recentMatches = [...(get().recentMatches || [])]
        const index = recentMatches.findIndex((m) => m.track.id === trackId)
        if (index !== -1) {
          recentMatches.splice(index, 1)
          set({ recentMatches })
        }
      },
      clearMatches: () => {
        return set({ recentMatches: null })
      },
    }),
    {
      name: 'recent-match-storage',
    }
  )
)

export default useRecentMatchStore
