import { create } from 'zustand'
import { matchfyApi } from '@/api'

export const useCuratorStore = create((set, getState) => ({
  error: false,
  isLoading: false,
  curators: undefined,
  currentCurator: undefined,
  currentCuratorId: undefined,
  success: false,
  getCurator: (userId) => {
    return getState().curators?.[userId] || null
  },
  loadCurator: async (userId) => {
    try {
      set({ isLoading: true })

      const curator = await matchfyApi.getCurator(userId)
      const curatorStats = await matchfyApi
        .getCuratorStats(userId)

      const curatorData = {
        ...curator,
        ...curatorStats,
      }

      set({
        currentCurator: curatorData,
        currentCuratorId: userId,
        curators: {
          ...getState().curators,
          [userId]: curatorData,
        },
      })

      set({ isLoading: false })
    } catch (error) {
      console.log(error)
      set({ isLoading: false })
    }
  },
  resetFeedback: () => set({ success: false, error: false }),
}))

export default useCuratorStore
