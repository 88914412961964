import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useAffiliateStore = create(
  persist(
    (set, get) => ({
      affiliate: '',
      setAffiliate: (affiliate) => set({ affiliate }),
      clearAffiliate: () => set({ affiliate: null })
    }),
    {
      name: 'affiliate-storage',
    }
  )
)

export default useAffiliateStore
