import { create } from 'zustand'
import { matchfyApi } from '@/api'

export const useEmailVerifyStore = create((set, get) => ({
  open: false,
  verified: null,
  loading: false,
  success: false,
  error: null,

  resetFeedback: () => set({ success: false, error: null }),

  verifyEmail: async (token) => {
    if (!token) return

    set({ loading: true })
    get().resetFeedback()

    try {
      await matchfyApi.verifyUserEmail(token)
      set({ success: true, verified: true })
    } catch (error) {
      if (error.response?.status === 400) {
        set({ error: error.response.data?.error, verified: false })
      }
    } finally {
      set({ loading: false })
    }
  },

  openPopup: () => {
    set({ open: true })
  },

  closePopup: () => {
    set({ open: false, verified: null })
  },

  onSubmit: () => {
    set({ open: false })
  }
}))
