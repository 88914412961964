import { create } from 'zustand'
import { spotifyApi } from '@/api'

export const usePlaylistTracksStore = create((set, get) => ({
  playlists: {},
  getPlaylistTracks: async (playlistId) => {
    const playlists = get().playlists

    if (playlists && playlists[playlistId]) {
      return playlists[playlistId]
    }

    const trackData = await spotifyApi.getPlaylistTracks(playlistId)
    const tracks = trackData.items.map(({ track }) => track)

    set({
      playlists: {
        ...playlists,
        [playlistId]: tracks,
      },
    })

    return tracks
  },
}))
