import { create } from 'zustand'
import { matchfyApi, spotifyApi } from '@/api'

export const usePushToPlaylistStore = create(
  (set, get) => ({
    loading: false,
    success: false,
    error: null,
    pushedCount: 0,
    pushedLimit: 50,
    pushedPlaylists: [],
    increment: () => set((state) => ({ pushedCount: state.pushedCount + 1 })),
    incrementLimit: () =>
      set((state) => ({ pushedLimit: state.pushedLimit * 2 })),
    setPushedPlaylists: (playlists) => set({ pushedPlaylists: playlists }),
    setPushedCount: (count) => set({ pushedCount: count }),
    resetPushedCount: () => set({ pushedCount: 0 }),
    resetPushedPlaylists: () => set({ pushedPlaylists: [] }),

    checkPushedPlaylist: (playlist) => {
      const pushedPlaylists = get().pushedPlaylists
      if (pushedPlaylists.includes(playlist?.id) || playlist?.isAlreadyPushed)
        return true
      return false
    },
    resetFeedback: () => set({ success: false, error: null }),

    autoFollowPlaylist: async (playlistId) => {
      try {
        await spotifyApi.followPlaylist(playlistId)
      } catch (error) {
        console.error('Error following playlist', error)
      }
    },

    pushToPlaylist: async (playlistId, track, checksum) => {
      const { resetFeedback } = get()
      set({ loading: true })
      resetFeedback()

      try {
        await matchfyApi.pushToPlaylist({
          playlistId,
          track,
          trackId: track.id,
          checksum,
        })

        set({ success: true })
      } catch (error) {
        set({ error: error?.response?.data?.message })
        console.error('Error pushing to playlist', error)
      } finally {
        set({ loading: false })
      }
    }
  })
)