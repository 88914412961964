import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useCreditsStore = create(
  persist(
    (set) => ({
      credits: 0,
      setCredits: (credits) => set({ credits }),
      addCredits: (credits) => set((state) => ({ credits: state.credits + credits })),
      removeCredits: (credits) => set((state) => ({ credits: state.credits - credits })),
    }),
    {
      name: 'credits-storage',
    }
  )
)

export default useCreditsStore
