import { create } from 'zustand'
import { matchfyApi } from '@/api'

export const matchStore = (set, get) => ({
  track: null,
  trackId: null,
  matchLocale: 'en',
  genres: [],
  isMatching: false,
  canMatch: true,
  matchChecksum: null,
  matchScore: null,
  matchType: null,
  matchValues: null,
  matches: {},
  success: null,
  error: null,
  isLoading: false,
  setCanMatch: (canMatch) => {
    set({ canMatch })
  },
  setIsMatching: (isMatching) => {
    set({ isMatching })
  },
  setMatchChecksum: (matchChecksum) => {
    set({ matchChecksum })
  },
  setMatchScore: (matchScore) => {
    set({ matchScore })
  },
  setMatchType: (matchType) => {
    set({ matchType })
  },
  setMatchValues: (matchValues) => {
    set({ matchValues })
  },
  setTrack: (track) => {
    set({ track })
  },
  setTrackId: (trackId) => {
    set({ trackId })
  },
  setPlaylistId: (playlistId) => {
    set({ playlistId })
  },
  setGenres: (genres) => {
    set({ genres })
  },
  setMatchLocale: (matchLocale) => {
    set({ matchLocale })
  },
  setSuccess: (success) => {
    set({ success })
  },
  setError: (error) => {
    set({ error })
  },
  setIsLoading: (isLoading) => {
    set({ isLoading })
  },
  getMatch: async (playlistId, trackId) => {
    const matchTrackId = trackId || get().trackId
    const matchKey = `${playlistId}-${matchTrackId}`
    const match = get().matches?.[matchKey]

    if (!match) {
      const { getPlaylistScore } = get()
      const matchData = await getPlaylistScore(playlistId)
      return matchData
    }

    return match
  },
  addMatch: (playlistId, trackId, match) => {
    const { matches, getMatch } = get()
    const matchKey = `${playlistId}-${trackId}`
    const existingMatch = getMatch(playlistId, trackId)

    if (!existingMatch?.values) {
      set({
        matches: {
          ...matches,
          [matchKey]: match,
        },
      })
    }
  },
  getPlaylistScore: async (playlistId) => {
    const { addMatch, track, trackId, setIsLoading, setSuccess, setError } = get()
    setIsLoading(true)

    try {
      const matchData = await matchfyApi.getMatchScore(
        playlistId,
        trackId,
        track?.audioFeatures
      )
      addMatch(playlistId, trackId, matchData)
      setSuccess('feedback.success.platform.analysis')
      setIsLoading(false)

      return matchData
    } catch (error) {
      console.log(error)
      setError('feedback.error.default')
      setIsLoading(false)
    }
  },
  clearMatchValues: () => {
    set({
      matchChecksum: null,
      matchScore: null,
      matchValues: null,
    })
  },
})

export const useMatchStore = create((...args) => ({
  ...matchStore(...args),
}))
