import { create } from 'zustand'
import { matchfyApi, spotifyApi } from '@/api'

export const usePushedStore = create((set) => ({
  pushed: [],
  error: false,
  isLoading: false,
  success: false,
  listPushedTracks: async () => {
    set({ isLoading: true })
    const pushed = await matchfyApi.listPushedTracks()

    const tracks = await Promise.all(
      pushed.map(async (pushedTrack) => {
        const track = await spotifyApi
          .getTrack(pushedTrack?.trackId)

        return {
          ...pushedTrack,
          ...track,
        }
      })
    )

    set({ pushed: tracks })
    set({ isLoading: false })
  },
}))
