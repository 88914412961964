export const CAMPAIGN_MANAGER_PRICE = 49
export const CAMPAIGN_ALGO_PRICE = 297
export const CAMPAIGN_ALGO_STEP = 250
export const CAMPAIGN_BOOST_PRICE = 49

export const CAMPAIGN_FORM_TYPOLOGIES = ['podcast', 'instagram']
export const CAMPAIGN_EXCLUSIVE_TYPOLOGIES = [
  'tiktokTalents',
  'instagram',
  'podcast',
]
export const CAMPAIGN_TYPOLOGY_WITH_TARGET = [
  'tiktok',
  'tiktokTalents',
  'youtube',
]

export const CAMPAIGN_TYPOLOGY_OPTIONS = (t) => [
  {
    icon: 'Spotify',
    iconType: 'brand',
    label: 'Spotify Playlist Promo',
    value: 'spotify',
    subtitle: t('vip_campaign.spotify.subtitle'),
    enabled: true,
  },
  {
    icon: 'TikTok',
    iconType: 'brand',
    label: 'TikTok Ads',
    value: 'tiktok',
    subtitle: t('vip_campaign.tiktok.subtitle'),
    enabled: true,
  },
  {
    icon: 'TikTok',
    iconType: 'brand',
    label: 'TikTok Talents',
    value: 'tiktokTalents',
    subtitle: t('vip_campaign.tiktok_talents.subtitle'),
    enabled: true,
  },
  {
    icon: 'Youtube',
    iconType: 'brand',
    label: 'YouTube Ads',
    value: 'youtube',
    subtitle: t('vip_campaign.youtube.subtitle'),
    enabled: true,
  },
  {
    icon: 'Instagram',
    iconType: 'brand',
    label: 'Instagram',
    value: 'instagram',
    subtitle: t('vip_campaign.instagram.subtitle'),
    enabled: true,
  },
  {
    icon: 'MicVocal',
    label: 'Podcast',
    value: 'podcast',
    subtitle: t('vip_campaign.podcast.subtitle'),
    enabled: true,
  },
]

export const CAMPAIGN_AGE_OPTIONS = (t) => [
  {
    label: t('common.labels.all', { gender: 'female' }),
    value: 'all',
  },
  {
    label: '18 - 24',
    value: '18-24',
  },
  {
    label: '25 - 34',
    value: '25-34',
  },
  {
    label: '35 - 44',
    value: '35-44',
  },
  {
    label: '45 - 54',
    value: '45-54',
  },
  {
    label: '55 - 64',
    value: '55-64',
  },
  {
    label: '65+',
    value: '65+',
  },
]

export const CAMPAIGN_GENDER_OPTIONS = (t) => [
  {
    label: t('common.labels.all', { gender: 'male' }),
    value: 'all',
  },
  {
    label: t('field.gender.options.male'),
    value: 'male',
  },
  {
    label: t('field.gender.options.female'),
    value: 'female',
  },
]

export const CAMPAIGN_DURATION_OPTIONS = (t) => [
  {
    label: t('common.time.periods.daily', { days: 7 }),
    value: 7,
  },
  {
    label: t('common.time.periods.daily', { days: 14 }),
    value: 14,
  },
  {
    label: t('common.time.periods.daily', { days: 21 }),
    value: 21,
  },
  {
    label: t('common.time.periods.daily', { days: 30 }),
    value: 30,
  },
]

export const CAMPAIGN_COUNTRY_OPTIONS = (t) => [
  {
    label: t('country.it'),
    value: 'it',
  },
  {
    label: t('country.uk'),
    value: 'uk',
  },
  {
    label: t('country.de'),
    value: 'de',
  },
  {
    label: t('country.fr'),
    value: 'fr',
  },
  {
    label: t('country.es'),
    value: 'es',
  },
  {
    label: t('country.us'),
    value: 'us',
  },
  {
    label: t('country.south_america'),
    value: 'south_america',
    subtitle: 'Coming soon',
    disabled: true,
  },
  {
    label: t('country.india'),
    value: 'india',
    subtitle: 'Coming soon',
    disabled: true,
  },
  {
    label: t('country.africa'),
    value: 'africa',
    subtitle: 'Coming soon',
    disabled: true,
  },
]

export const CAMPAIGN_BUDGET_OPTIONS = [
  {
    id: 'spotify',
    iconType: 'brand',
    icon: 'Spotify',
    label: 'Spotify Playlist Promo',
    min: 50,
    step: 50,
    max: 10000,
  },
  {
    id: 'tiktok',
    iconType: 'brand',
    icon: 'TikTok',
    label: 'TikTok Ads',
    min: 50,
    step: 50,
    max: 10000,
  },
  {
    id: 'tiktokTalents',
    icon: 'UserRound',
    label: 'TikTok Talents',
    hide: true,
  },
  {
    id: 'youtube',
    icon: 'Youtube',
    iconType: 'brand',
    label: 'YouTube Ads',
    min: 50,
    step: 50,
    max: 10000,
  },
  {
    id: 'instagram',
    iconType: 'brand',
    icon: 'Instagram',
    label: 'Instagram',
    min: 500,
    step: 100,
    max: 10000,
  },
  {
    id: 'podcast',
    icon: 'MicVocal',
    label: 'Podcast',
    min: 500,
    step: 100,
    max: 10000,
  },
]

export const TIKTOK_TALENTS_OPTIONS = [
  {
    label: '10 Talents',
    value: 10,
  },
  {
    label: '15 Talents',
    value: 15,
  },
  {
    label: '30 Talents',
    value: 30,
  },
  {
    label: '50 Talents',
    value: 50,
  },
  {
    label: '70 Talents',
    value: 70,
  },
  {
    label: '115 Talents',
    value: 115,
  },
  {
    label: '150 Talents',
    value: 150,
  },
]
