import { create } from 'zustand'
import { matchfyApi } from '@/api'

export const usePlaylistStore = create((set, getState) => ({
  error: false,
  isLoading: false,
  playlists: undefined,
  currentPlaylist: undefined,
  currentPlaylistId: undefined,
  success: false,
  watch: false,
  loadPlaylist: async (playlistId) => {
    const { setPlaylist } = getState()

    const playlistData = await matchfyApi.getPlaylist(playlistId)

    setPlaylist(playlistId, playlistData)

    return playlistData
  },
  getTracks: (playlistId) => {
    const playlistTracks = getState().playlists?.[playlistId]?.tracks
    if (!playlistTracks || !playlistTracks?.length) return []
    return playlistTracks.map((item) => item.track)
  },
  setIsLoading: (isLoading) => set({ isLoading }),
  setPlaylist: (playlistId, playlist) => {
    set((state) => ({
      playlists: {
        ...state.playlists,
        [playlistId]: {
          ...state[playlistId],
          ...playlist,
        },
      },
      currentPlaylist: playlist,
      currentPlaylistId: playlistId,
    }))
  },
  setWatch: (watch) => set({ watch }),
  addTrackToPlaylist: (playlistId, track) => {
    set((state) => ({
      playlists: {
        ...state.playlists,
        [playlistId]: {
          ...state[playlistId],
          tracks: [...state[playlistId].tracks, track],
        },
      },
      success: 'feedback.success.track.add.simple',
    }))
  },
  undoPushAccept: (playlistId, trackId) => {
    set((state) => ({
      playlists: {
        ...state.playlists,
        [playlistId]: {
          ...state[playlistId],
          tracks: state[playlistId].tracks.filter(
            (t) => t.track.id !== trackId
          ),
        },
      },
      success: 'feedback.success.track.remove.playlist',
    }))
  },
  updatePlaylistGenres: async (genres) => {
    try {
      const playlist = getState().currentPlaylist
      const { playlistId } = playlist
      await matchfyApi.updatePlaylistGenres(playlistId, genres)

      set(() => ({ success: 'feedback.success.tags.update_request' }))
    } catch (error) {
      set(() => ({ error: 'feedback.error.generic' }))
      throw error
    }
  },

  requestPlaylistGenresUpdate: async () => {
    try {
      const playlistId = getState().currentPlaylistId
      await matchfyApi
        .requestPlaylistGenresUpdate(playlistId)

      set(() => ({ success: 'feedback.success.tags.updated' }))
    } catch (error) {
      set(() => ({ error: 'feedback.error.generic' }))
      throw error
    }
  },
  toggleVerification: async (playlistId, isPlaylistVerified) => {
    try {
      const feedbackMessage = isPlaylistVerified
        ? 'feedback.success.playlist.verify.enable'
        : 'feedback.success.playlist.verify.disable'
      await matchfyApi
        .togglePlaylistEnabled(playlistId, isPlaylistVerified)

      set(() => ({ success: feedbackMessage }))
    } catch (error) {
      const message = error?.response?.data?.message || 'feedback.error.generic'
      set(() => ({ error: message }))
      throw error
    }
  },
  toggleWatch: async (playlistId, watch) => {
    try {
      const feedbackMessage = watch
        ? 'feedback.success.playlist.watch.enable'
        : 'feedback.success.playlist.watch.disable'

      if (watch) {
        await matchfyApi.watchPlaylist(playlistId)
      } else {
        await matchfyApi.unwatchPlaylist(playlistId)
      }
      set(() => ({ success: feedbackMessage, watch }))
    } catch (error) {
      const message = error?.response?.data?.message || 'feedback.error.generic'
      set(() => ({ error: message }))
      throw error
    }
  },
  autoAcceptEnable: async (playlistId, rate) => {
    await matchfyApi.autoAcceptEnable(playlistId, rate)
    set(() => ({ success: 'feedback.success.auto_accept.enable' }))
  },
  autoAcceptDisable: async (playlistId) => {
    await matchfyApi.autoAcceptDisable(playlistId)
    set(() => ({ success: 'feedback.success.auto_accept.disable' }))
  },
  getPlaylistById: (playlistId) => {
    const playlists = getState().playlists
    const playlist = Object.values(playlists).find(
      (p) => p.playlistId === playlistId
    )

    if (!playlist) return
    return playlist
  },
  getPlaylistFeatures: async (playlistId) => {
    return getState().playlists[playlistId].features
  },
  getPlaylistAudioFeatures: async (playlistId) => {
    return getState().playlists[playlistId].audioFeatures
  },
  resetTracks: (playlistId) =>
    set((state) => ({
      playlists: {
        ...state.playlists,
        [playlistId]: {
          ...state[playlistId],
          tracks: [],
        },
      },
    })),
  resetFeedback: () => set({ success: false, error: false }),
}))

export default usePlaylistStore
